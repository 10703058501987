
.main-sentence {
    font-weight: 500;
    border-radius: 5px;
    background: #FFF2C5;
    padding: 0px 2px 0px 15px;
    position: relative;
}

.wrapper-dark .main-sentence {
    background: rgba(255, 242, 197, 0.25);
}

.main-sentence svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
}

.MuiButton-outlined.Mui-active {
    border: 1px solid #4A14FF !important;
    position: relative;
}

.MuiButton-outlined::before {
    content: '';
    position: absolute;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 14px;
    pointer-events: none;
    border: 2px solid transparent;
    transition: all 0.1s linear;
}

.MuiButton-outlined.Mui-active::before {
    border: 2px solid rgba(104, 51, 255, 0.20);
}
